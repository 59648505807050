:root {
  --font-family-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica ,Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-family: var(--font-family-sans);
  --color-background: white;
  --color-foreground-muted: #f9fafb;
  --color-foreground: #f3f4f6;
  --color-foreground-accent: #d1d5db;
  --color-text-muted: #6b7280;
  --color-text-base: #1f2937;
  --color-text-emphasis: #030712;
  --color-text-highlight: #bae6fd;

  --link-color: inherit;
  --link-decoration: underline;
  --link-decoration-hover: underline;
  --link-decoration-style: line;
  --link-decoration-color: inherit;
  --link-decoration-thickness: 0.0625em;

  --text-size-xs: clamp(0.74rem, calc(0.73rem + 0.04vw), 0.76rem);
  --text-size-xs-line-height: 1.5;
  --text-size-s: clamp(0.89rem, calc(0.86rem + 0.11vw), 0.95rem);
  --text-size-s-line-height: 1.5;
  --text-size-m: clamp(1.06rem, calc(1.02rem + 0.22vw), 1.19rem);
  --text-size-m-line-height: 1.5;
  --text-size-l: clamp(1.28rem, calc(1.20rem + 0.36vw), 1.48rem);
  --text-size-l-line-height: 1.4;
  --text-size-xl: clamp(1.53rem, calc(1.42rem + 0.57vw), 1.86rem);
  --text-size-xl-line-height: 1.3;
  --text-size-2xl: clamp(1.84rem, calc(1.67rem + 0.84vw), 2.32rem);
  --text-size-2xl-line-height: 1.3;
  --text-size-3xl: clamp(2.20rem, calc(1.96rem + 1.21vw), 2.90rem);
  --text-size-3xl-line-height: 1.3;

  --space-3xs: clamp(0.31rem, calc(0.31rem + 0.00vw), 0.31rem);
  --space-2xs: clamp(0.56rem, calc(0.54rem + 0.11vw), 0.63rem);
  --space-xs: clamp(0.88rem, calc(0.85rem + 0.11vw), 0.94rem);
  --space-s: clamp(1.13rem, calc(1.08rem + 0.22vw), 1.25rem);
  --space-m: clamp(1.69rem, calc(1.62rem + 0.33vw), 1.88rem);
  --space-l: clamp(2.25rem, calc(2.16rem + 0.43vw), 2.50rem);
  --space-xl: clamp(3.38rem, calc(3.24rem + 0.65vw), 3.75rem);
  --space-2xl: clamp(4.50rem, calc(4.33rem + 0.87vw), 5.00rem);
  --space-3xl: clamp(6.75rem, calc(6.49rem + 1.30vw), 7.50rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.31rem, calc(0.20rem + 0.54vw), 0.63rem);
  --space-2xs-xs: clamp(0.56rem, calc(0.43rem + 0.65vw), 0.94rem);
  --space-xs-s: clamp(0.88rem, calc(0.74rem + 0.65vw), 1.25rem);
  --space-s-m: clamp(1.13rem, calc(0.86rem + 1.30vw), 1.88rem);
  --space-m-l: clamp(1.69rem, calc(1.40rem + 1.41vw), 2.50rem);
  --space-l-xl: clamp(2.25rem, calc(1.73rem + 2.61vw), 3.75rem);
  --space-xl-2xl: clamp(3.38rem, calc(2.81rem + 2.83vw), 5.00rem);
  --space-2xl-3xl: clamp(4.50rem, calc(3.46rem + 5.22vw), 7.50rem);

  --breakpoint-xs: 480px;
  --breakpoint-s: 640px;
  --breakpoint-m: 768px;
  --breakpoint-l: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-2xl: 1536px;
  --border-radius: 5px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #18181b;
    --color-foreground-muted: #27272a;
    --color-foreground: #27272a;
    --color-foreground-accent: #3f3f46;
    --color-text-muted: #a1a1aa;
    --color-text-base: #f4f4f5;
    --color-text-emphasis: #fafafa;
    --color-text-highlight: #0369a1;
  }  
}

[distribute] {
  display: flex;
  flex-wrap: nowrap;
}
[distribute="start"] { justify-content: start; }
[distribute="center"] { justify-content: center; }
[distribute="across"] { justify-content: space-between; }
[distribute="end"] { justify-content: end; }
[align="start"] { align-items: start; }
[align="center"] { align-items: center; }
[align="end"] { align-items: end; }
[distribute][direction="horizontal"] { flex-direction: row; }
[distribute][direction="vertical"] { flex-direction: column; }
[distribute] > [expand] { flex-grow: 1; }

[container] {
  margin: 0 auto;
  max-width: var(--breakpoint-m);
}

[max-width="none"] { max-width: none; }
[max-width="xs"] { max-width: var(--breakpoint-xs); }
[max-width="s"] { max-width: var(--breakpoint-s); }
[max-width="m"] { max-width: var(--breakpoint-m) }
[max-width="l"] { max-width: var(--breakpoint-l); }
[max-width="xl"] { max-width: var(--breakpoint-xl); }
[max-width="2xl"] { max-width: var(--breakpoint-2xl); }

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  font-size: 16px;
}

html, body {
  min-height: 100%;
  font-family: var(--font-family);
}

body {
  background-color: var(--color-background);
  color: var(--color-text-base);
  font-size: var(--text-size-m);
  line-height: var(--text-size-m-line-height);
  margin: 0 auto;
  max-width: var(--breakpoint-m);
  padding: var(--space-m-l);
}

section {
  margin: var(--space-2xl) 0;
}

h1 {
  color: var(--color-text-emphasis);
  font-size: var(--text-size-xl);
  font-weight: 800;
  line-height: var(--text-size-xl-line-height);
  margin: var(--space-l) 0 var(--space-s) 0;
}

h2 {
  color: var(--color-text-emphasis);
  font-weight: 700;
  font-size: var(--text-size-l);
  line-height: var(--text-size-l-line-height);
  margin: var(--space-l) 0 var(--space-s) 0;
}

h3, h4, h5, h6 {
  color: var(--color-text-emphasis);
  font-weight: 700;
  font-size: var(--text-size-m);
  line-height: var(--text-size-m-line-height);
  margin: var(--space-l) 0 var(--space-s) 0;
}

hgroup {
  margin: var(--space-l) 0 var(--space-s) 0;
}

hgroup > * {
  margin: 0 !important;
}

p {
  margin: var(--space-s) 0;
}

pre {
  background-color: var(--color-foreground);
  border-radius: var(--border-radius);
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  font-size: var(--text-size-s);
  line-height: var(--text-size-s-line-height);
  padding: var(--space-s);
}

code {
  background-color: var(--color-foreground);
  border-radius: var(--border-radius);
  font-family: var(--font-family-mono);
  padding: .25em;
}

pre > code {
  padding: 0;
}

hr {
  display: block;
  height: 1px;
  font-size: 1px;
  border: none;
  margin: var(--space-l) auto;
  background-color: var(--color-foreground-accent);
}

a {
  background-color: transparent;
  border-radius: var(--border-radius);
  color: var(--color-text-emphasis);
  margin: -.1em -.2em;
  padding: .1em .2em;
  text-decoration: var(--link-decoration);
  text-decoration-line: var(--link-decoration);
  text-decoration-style: var(--link-decoration-style);
  text-decoration-color: var(--link-decoration-color);
  text-decoration-thickness: var(--link-decoration-thickness);
  transition: background-color .2s ease;
}

a:active {
  text-decoration: var(--link-decoration-hover);
  text-decoration-line: var(--link-decoration-hover);
  text-decoration-style: var(--link-decoration-style);
  text-decoration-color: var(--link-decoration-color);
  text-decoration-thickness: var(--link-decoration-thickness);
}

@media (hover: hover) {
  a:hover {
    text-decoration: var(--link-decoration-hover);
    text-decoration-line: var(--link-decoration-hover);
    text-decoration-style: var(--link-decoration-style);
    text-decoration-color: var(--link-decoration-color);
    text-decoration-thickness: var(--link-decoration-thickness);
  }
}

a:visited {
  color: inherit;
}

ul, ol {
  margin: var(--space-s) 0;
  padding-left: var(--space-l);
}

li {
  margin: 0;  
}

ol li::marker {
  color: inherit;
}

ul li::marker {
  color: var(--color-text-muted);
}

blockquote {
  font-size: 90%;
  margin: var(--space-s) 0;
  padding: 0 var(--space-l);
}

blockquote > *:first-child, aside > *:first-child {
  margin-top: 0;
}

blockquote > *:last-child, aside > *:last-child {
  margin-bottom: 0;
}

aside {
  background-color: var(--color-foreground);
  border-top: 1px solid var(--color-foreground-accent);
  border-bottom: 1px solid var(--color-foreground-accent);
  font-size: 90%;
  margin: var(--space-s) 0;
  padding: var(--space-s);
}

small {
  color: var(--color-text-muted);
  font-size: var(--text-size-xs);
}

/**
Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

mark {
  background-color: var(--color-text-highlight);
  color: var(--color-text-base);
}

figure {
  margin: var(--space-s) 0;
  position: relative;
  width: 100%;
  height: auto;
}

figure > img, figure > div, figure > svg {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

figure > figcaption {
  color: var(--color-text-muted);
  font-size: var(--text-size-s);
  margin-top: 0.75rem;
  text-align: center;
}

/*
Forms
=====
*/

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 1em; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
Restore the focus styles unset by the previous rule.
*/

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/**
Remove the padding so developers are not caught out when they zero out 'fieldset' elements in all browsers.
*/

legend {
  padding: 0;
}

label {
  display: block;
  font-size: var(--text-size-s);
  font-weight: 500;
}

label + input, label + select, label + textarea {
  margin-top: var(--space-2xs);
}

input, select, textarea {
  appearance: none;
  background-color: var(--color-background);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-foreground-accent);
  box-sizing: border-box;
  color: var(--color-text-base);
  display: block;
  margin: 0 0 var(--space-s) 0;
  padding: .375rem .6125rem;
  width: 100%;
}

select:not([multiple]) {
  cursor: pointer;
  padding-right: 2em;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 15px) 50%,
    calc(100% - 10px) 50%,
    calc(100% - 2em) 0.4em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1em;
  background-repeat: no-repeat;
}

button, [type="button"] {
  appearance: none;
  align-items: center;
  background-color: var(--color-foreground);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-foreground-accent);
  box-shadow: inset 0px 1px 0px 1px var(--color-foreground-muted);
  display: inline-flex;
  font-weight: 500;
  color: var(--color-text-base);
  cursor: pointer;
  line-height: 1.15; /* 1 */
  padding: .375rem  .6125rem;
  text-decoration: none;
  vertical-align: middle;
}

@media (hover: hover) {
  a[type="button"] {
    background-color: var(--color-foreground);
  }
}

button:active, [type="button"]:active {
  box-shadow: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button[aria-busy]::before, [type="button"][aria-busy]::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid .2rem var(--color-foreground-accent);
  border-bottom-color: var(--color-text-base);
  border-radius: 50%;
  display: block;
  content: "";
  height: .75em;
  width: .75em;
  margin-right: var(--space-2xs);
}

[margin="none"] { margin: 0; } 
[margin="3xs"] { margin: var(--space-3xs); }
[margin="2xs"] { margin: var(--space-2xs); }
[margin="xs"] { margin: var(--space-xs); }
[margin="s"] { margin: var(--space-s); }
[margin="m"] { margin: var(--space-m); }
[margin="l"] { margin: var(--space-l); }
[margin="xl"] { margin: var(--space-xl); }
[margin="2xl"] { margin: var(--space-2xl); }
[margin="3xl"] { margin: var(--space-3xl); }
[margin-horizontal="auto"] { margin: 0 auto; }
[margin-horizontal="none"] { margin-left: 0; margin-right: 0; }
[margin-horizontal="3xs"] { margin: 0 var(--space-3xs); }
[margin-horizontal="2xs"] { margin: 0 var(--space-2xs); }
[margin-horizontal="xs"] { margin: 0 var(--space-xs); }
[margin-horizontal="s"] { margin: 0 var(--space-s); }
[margin-horizontal="m"] { margin: 0 var(--space-m); }
[margin-horizontal="l"] { margin: 0 var(--space-l); }
[margin-horizontal="xl"] { margin: 0 var(--space-xl); }
[margin-horizontal="2xl"] { margin: 0 var(--space-2xl); }
[margin-horizontal="3xl"] { margin: 0 var(--space-3xl); }
[margin-vertical="none"] { margin-top: 0; margin-bottom: 0; }
[margin-vertical="3xs"] { margin: var(--space-3xs) 0; }
[margin-vertical="2xs"] { margin: var(--space-2xs) 0; }
[margin-vertical="xs"] { margin: var(--space-xs) 0; }
[margin-vertical="s"] { margin: var(--space-s) 0; }
[margin-vertical="m"] { margin: var(--space-m) 0; }
[margin-vertical="l"] { margin: var(--space-l) 0; }
[margin-vertical="xl"] { margin: var(--space-xl) 0; }
[margin-vertical="2xl"] { margin: var(--space-2xl) 0; }
[margin-vertical="3xl"] { margin: var(--space-3xl) 0; }
[margin-top="none"] { margin-top: 0; }
[margin-top="3xs"] { margin-top: var(--space-3s); }
[margin-top="2xs"] { margin-top: var(--space-2s); }
[margin-top="xs"] { margin-top: var(--space-xs); }
[margin-top="s"] { margin-top: var(--space-s); }
[margin-top="m"] { margin-top: var(--space-m); }
[margin-top="l"] { margin-top: var(--space-l); }
[margin-top="xl"] { margin-top: var(--space-xl); }
[margin-top="2xl"] { margin-top: var(--space-2xl); }
[margin-bottom="none"] { margin-bottom: 0; }
[margin-bottom="3xs"] { margin-bottom: var(--space-3xs); }
[margin-bottom="2xs"] { margin-bottom: var(--space-2xs); }
[margin-bottom="xs"] { margin-bottom: var(--space-xs); }
[margin-bottom="s"] { margin-bottom: var(--space-s); }
[margin-bottom="m"] { margin-bottom: var(--space-m); }
[margin-bottom="l"] { margin-bottom: var(--space-l); }
[margin-bottom="xl"] { margin-bottom: var(--space-xl); }
[margin-bottom="2xl"] { margin-bottom: var(--space-2xl); }
[margin-bottom="3xl"] { margin-bottom: var(--space-3xl); }
[margin-left="none"] { margin-left: 0; }
[margin-left="3xs"] { margin-left: var(--space-3xs); }
[margin-left="2xs"] { margin-left: var(--space-2xs); }
[margin-left="xs"] { margin-left: var(--space-xs); }
[margin-left="s"] { margin-left: var(--space-s); }
[margin-left="m"] { margin-left: var(--space-m); }
[margin-left="l"] { margin-left: var(--space-l); }
[margin-left="xl"] { margin-left: var(--space-xl); }
[margin-left="2xl"] { margin-left: var(--space-2xl); }
[margin-left="3xl"] { margin-left: var(--space-3xl); }
[margin-right="none"] { margin-right: 0; }
[margin-right="3xs"] { margin-right: var(--space-3xs); }
[margin-right="2xs"] { margin-right: var(--space-2xs); }
[margin-right="xs"] { margin-right: var(--space-xs); }
[margin-right="s"] { margin-right: var(--space-s); }
[margin-right="m"] { margin-right: var(--space-m); }
[margin-right="l"] { margin-right: var(--space-l); }
[margin-right="xl"] { margin-right: var(--space-xl); }
[margin-right="2xl"] { margin-right: var(--space-2xl); }
[margin-right="3xl"] { margin-right: var(--space-3xl); }
[padding="3xs"] { padding: var(--space-3xs); }
[padding="2xs"] { padding: var(--space-2xs); }
[padding="xs"] { padding: var(--space-xs); }
[padding="s"] { padding: var(--space-s); }
[padding="m"] { padding: var(--space-m); }
[padding="l"] { padding: var(--space-l); }
[padding="xl"] { padding: var(--space-xl); }
[padding="2xl"] { padding: var(--space-2xl); }
[padding="3xl"] { padding: var(--space-3xl); }
[padding-horizontal="auto"] { padding: 0 auto; }
[padding-horizontal="none"] { padding-left: 0; padding-right: 0; }
[padding-horizontal="3xs"] { padding: 0 var(--space-3xs); }
[padding-horizontal="2xs"] { padding: 0 var(--space-2xs); }
[padding-horizontal="xs"] { padding: 0 var(--space-xs); }
[padding-horizontal="s"] { padding: 0 var(--space-s); }
[padding-horizontal="m"] { padding: 0 var(--space-m); }
[padding-horizontal="l"] { padding: 0 var(--space-l); }
[padding-horizontal="xl"] { padding: 0 var(--space-xl); }
[padding-horizontal="2xl"] { padding: 0 var(--space-2xl); }
[padding-horizontal="3xl"] { padding: 0 var(--space-3xl); }
[padding-vertical="none"] { padding-top: 0; padding-bottom: 0; }
[padding-vertical="3xs"] { padding: var(--space-3xs) 0; }
[padding-vertical="2xs"] { padding: var(--space-2xs) 0; }
[padding-vertical="xs"] { padding: var(--space-xs) 0; }
[padding-vertical="s"] { padding: var(--space-s) 0; }
[padding-vertical="m"] { padding: var(--space-m) 0; }
[padding-vertical="l"] { padding: var(--space-l) 0; }
[padding-vertical="xl"] { padding: var(--space-xl) 0; }
[padding-vertical="2xl"] { padding: var(--space-2xl) 0; }
[padding-vertical="3xl"] { padding: var(--space-3xl) 0; }
[padding-top="none"] { padding-top: 0; }
[padding-top="3xs"] { padding-top: var(--space-3s); }
[padding-top="2xs"] { padding-top: var(--space-2s); }
[padding-top="xs"] { padding-top: var(--space-xs); }
[padding-top="s"] { padding-top: var(--space-s); }
[padding-top="m"] { padding-top: var(--space-m); }
[padding-top="l"] { padding-top: var(--space-l); }
[padding-top="xl"] { padding-top: var(--space-xl); }
[padding-top="2xl"] { padding-top: var(--space-2xl); }
[padding-bottom="none"] { padding-bottom: 0; }
[padding-bottom="3xs"] { padding-bottom: var(--space-3xs); }
[padding-bottom="2xs"] { padding-bottom: var(--space-2xs); }
[padding-bottom="xs"] { padding-bottom: var(--space-xs); }
[padding-bottom="s"] { padding-bottom: var(--space-s); }
[padding-bottom="m"] { padding-bottom: var(--space-m); }
[padding-bottom="l"] { padding-bottom: var(--space-l); }
[padding-bottom="xl"] { padding-bottom: var(--space-xl); }
[padding-bottom="2xl"] { padding-bottom: var(--space-2xl); }
[padding-bottom="3xl"] { padding-bottom: var(--space-3xl); }
[padding-left="none"] { padding-left: 0; }
[padding-left="3xs"] { padding-left: var(--space-3xs); }
[padding-left="2xs"] { padding-left: var(--space-2xs); }
[padding-left="xs"] { padding-left: var(--space-xs); }
[padding-left="s"] { padding-left: var(--space-s); }
[padding-left="m"] { padding-left: var(--space-m); }
[padding-left="l"] { padding-left: var(--space-l); }
[padding-left="xl"] { padding-left: var(--space-xl); }
[padding-left="2xl"] { padding-left: var(--space-2xl); }
[padding-left="3xl"] { padding-left: var(--space-3xl); }
[padding-right="none"] { padding-right: 0; }
[padding-right="3xs"] { padding-right: var(--space-3xs); }
[padding-right="2xs"] { padding-right: var(--space-2xs); }
[padding-right="xs"] { padding-right: var(--space-xs); }
[padding-right="s"] { padding-right: var(--space-s); }
[padding-right="m"] { padding-right: var(--space-m); }
[padding-right="l"] { padding-right: var(--space-l); }
[padding-right="xl"] { padding-right: var(--space-xl); }
[padding-right="2xl"] { padding-right: var(--space-2xl); }
[padding-right="3xl"] { padding-right: var(--space-3xl); }

[text-type="monospace"] { font-family: var(--font-family-mono); }
[text-type="sans-serif"] { font-family: var(--font-family-sans); }
[text-type="serif"] { font-family: var(--font-family-serif); }
[text-align="left"] { text-align: left; }
[text-align="center"] { text-align: center; }
[text-align="right"] { text-align: right; }
[text-size="xs"] {
  font-size: var(--text-size-xs);
  line-height: var(--text-size-xs-line-height);
}
[text-size="s"] {
  font-size: var(--text-size-s);
  line-height: var(--text-size-s-line-height);
}
[text-size="m"] {
  font-size: var(--text-size-m);
  line-height: var(--text-size-m-line-height);
}
[text-size="l"] {
  font-size: var(--text-size-l);
  line-height: var(--text-size-l-line-height);
}
[text-size="xl"] {
  font-size: var(--text-size-xl);
  line-height: var(--text-size-xl-line-height);
}
[text-size="2xl"] {
  font-size: var(--text-size-2xl);
  line-height: var(--text-size-2xl-line-height);
}
[text-size="3xl"] {
  font-size: var(--text-size-3xl);
  line-height: var(--text-size-3xl-line-height);
}
[text-color="muted"] { color: var(--color-text-muted); }
[text-color="base"] { color: var(--color-text-base); }
[text-color="emphasis"] { color: var(--color-text-emphasis); }
[text-weight="100"] { font-weight: 100; }
[text-weight="200"] { font-weight: 200; }
[text-weight="300"] { font-weight: 300; }
[text-weight="400"] { font-weight: 400; }
[text-weight="500"] { font-weight: 500; }
[text-weight="600"] { font-weight: 600; }
[text-weight="700"] { font-weight: 700; }
[text-weight="800"] { font-weight: 800; }
[text-weight="900"] { font-weight: 900; }
[text-decoration="none"] { text-decoration: none; }
[text-transform="uppercase"] { text-transform: uppercase; }
[text-transform="lowercase"] { text-transform: lowercase; }

[rounded] { border-radius: 50%; }
